import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

export default function BrandList() {
  const data = useStaticQuery(graphql`
  query TyreBrands {
    allTyreBrand {
      edges {
        node {
          pageUrl,
          brand
        }
      }
    }
  }
`)

  const results = [];

  data.allTyreBrand.edges.forEach(brand => {
    results.push(
      <div key={brand.node.pageUrl}>
        <Link to={brand.node.pageUrl}>{brand.node.brand}</Link>
      </div>,
    );
  });

  return (
    <div>
      {results}
    </div>
  );
}