/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import { AwsRum, AwsRumConfig } from 'aws-rum-web';

if(process.env.NODE_ENV !== "development") {
  try {
    const config = {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::637345297332:role/RUM-Monitor-eu-north-1-637345297332-1269360983761-Unauth",
      identityPoolId: "eu-north-1:5a229751-b924-4b73-91ea-791f10fb451d",
      endpoint: "https://dataplane.rum.eu-north-1.amazonaws.com",
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: true
    };

    const APPLICATION_ID = 'b3e7ca08-f14e-4df4-a998-21be8e822e6e';
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = 'eu-north-1';

    const awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}